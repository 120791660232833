<template>
  <v-container>
    <div class="headline mb-6 mt-1">
      {{ $t('message.admin.subjects_management') }}
    </div>
    <v-row>
      <v-col cols="12">
        <table-persons
          @create="openCreateSubjectModal"
          @edit="openEditSubjectModal"
          @updatePage="_updatePage"
          @updateSearchText="_findPersonsByText"
          @removeItem="removeSubject"
          @removeMultiplyItem="removeMultiplySubject"
          :persons="getSubjectsList"
          :totalDesserts="table.totalDesserts"
          :totalPages="table.totalPages"
          :currentPage="table.page"
          :loading="isLoading"
          :headers="headers"
          ref="tableSubjects"
        />
      </v-col>
    </v-row>
    <subject-modal
      v-model="createSubjectModal.show"
      @submit="createSubject"
    />
    <subject-modal
      v-model="editSubjectModal.show"
      title="Edit Subjects"
      :name="getSubjectName"
      @submit="updateSubject"
      @hide="clearEditSubject"
    />
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import TablePersons from '@components/Admin/TablePersons/TablePersons.vue';
import subjectModal from '@components/Modals/subjectModal.vue';
import tablePersons from '@/mixins/Table/tablePersons';

export default {
  name: 'SubjectsManagements',
  components: {
    TablePersons,
    subjectModal,
  },
  mixins: [
    tablePersons,
  ],
  props: {},
  computed: {
    ...mapGetters({
      subjectsLoading: 'subjects/isLoading',
      subjectLoading: 'subject/isLoading',
      subjects: 'subjects/getSubjectsWithPagination',
      subject: 'subject/getSubject',
    }),
    isLoading() {
      return this.subjectsLoading || this.subjectLoading;
    },
    getSubjectsList() {
      return this.subjects.data;
    },
    getSubjectName() {
      return this.editSubjectModal.subject && this.editSubjectModal.subject.name;
    },
  },
  data() {
    return {
      headers: [
        {
          text: '',
          value: 'checkbox',
          sortable: false,
          width: '30px',
        },
        {
          text: window.app.$t('message.admin.id'),
          value: 'id',
          sortable: false,
          width: '30px',
        },
        {
          text: window.app.$t('message.admin.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: window.app.$t('message.admin.actions'),
          value: 'actions',
          sortable: false,
          width: '120px',
        },
      ],
      createSubjectModal: {
        show: false,
      },
      editSubjectModal: {
        show: false,
        subject: null,
      },
      tableContainer: null,
      table: {
        page: 1,
        search: null,
        role_id: null,
        totalDesserts: 0,
        totalPages: 0,
      },
    };
  },
  created() {
    this.getDataTable();
  },
  mounted() {
    this.tableContainer = this.$refs.tableSubjects;
  },
  methods: {
    getDataTable(params) {
      return this.$api.subjects.getSubjects(params).then(() => {
        this.table.totalDesserts = this.subjects.meta.pagination.total;
        this.table.totalPages = this.subjects.meta.pagination.total_pages;
        this.table.page = this.subjects.meta.pagination.current_page;
      });
    },
    openCreateSubjectModal() {
      this.createSubjectModal.show = true;
    },
    createSubject(formData) {
      this.$api.subjects.createSubject(formData).then(() => {
        this._updateTable(this.table);
      });
    },
    updateSubject(formData) {
      this.$api.subjects.updateSubject(formData, this.subject.id).then((res) => {
        this.$store.dispatch('subjects/updateSubject', res.data.subject.data);
      });
    },
    openEditSubjectModal({ id }) {
      this.editSubjectModal.show = true;
      this.$api.subjects.getSubject(id).then((res) => {
        this.editSubjectModal.subject = res.data;
      });
    },
    clearEditSubject() {
      this.editSubjectModal.subject = null;
    },
    async removeSubject(idSubject) {
      const confirm = await this.$root.$confirm.open(
        this.$t('message.admin.delete'),
        this.$t('message.admin.are_you_sure_you_want_to_delete_a_subject'),
      );

      if (confirm) {
        await this.$api.subjects.removeSubject(idSubject);
        await this._updateTable(this.table);
        this.tableContainer.selected = [];
      }
    },
    async removeMultiplySubject(idsSubject) {
      if (!idsSubject.length) {
        this._showErrorNotify(this.$t('message.admin.please_select_subjects'));
        return;
      }

      const confirm = await this.$root.$confirm.open(
        this.$t('message.admin.delete'),
        this.$t('message.admin.are_you_sure_you_want_to_delete_a_subject'),
      );

      if (confirm) {
        await this.$api.subjects.removeMultiplySubject({ ids: idsSubject });
        await this._updateTable(this.table);
        this.tableContainer.selected = [];
      }
    },
  },
};
</script>
