import rules from '@helpers/rules';

export default {
  data: () => ({
    formValidGlobal: true,
  }),
  computed: {
    _rules() {
      return {
        required: value => rules.required(value) || window.app.$t('message.fieldIsRequired'),
        requiredSingleEvent: value => (this.isSingleEvent ? rules.required(value) || window.app.$t('message.fieldIsRequired') : true),
        requiredRecurringEvent: value => (!this.isSingleEvent ? rules.required(value) || window.app.$t('message.fieldIsRequired') : true),
        selectRequired: value => value === false || rules.selectRequired(value) || window.app.$t('message.fieldIsRequired'),
        time: value => (rules.time(value) && value.length === 5 ? true : window.app.$t('message.fieldIsNotValid')),
        minTime: value => (rules.minTime(value, '00:00') || `${window.app.$t('message.minTime')} 00:00`),
        maxTime: value => (rules.maxTime(value, '23:59') || `${window.app.$t('message.maxTime')} 23:59`),
        minEndTime: (value) => {
          const minTime = this.$moment(this.form.start_time, 'HH:mm').add(5, 'minutes').format('HH:mm');
          return (rules.minTime(value, minTime) || `${window.app.$t('message.minTime')} ${minTime}`);
        },
        email: value => rules.email(value) || window.app.$t('message.emailError'),
        password: value => rules.password(value) || window.app.$t('message.passwordError'),
        multiSelectRequired: value => value.length !== 0 || window.app.$t('message.fieldIsRequired'),
        aplhWithSpaceWithDash: value => !value || /^[a-zA-Z]+((\s|-|,|\.)+[a-zA-Z]+)*$/.test(value) || window.app.$t('message.onlyAplhWithSpaceWithDash'),
        phone: value => !value || rules.phone(value) || window.app.$t('message.phoneNumberNotValid'),
        // number: value => rules.isNumber(value) || 'message.fieldMustBeNumber',
      };
    },
  },
  methods: {
    formValidate(form) {
      return form.validate();
    },

    formReset(form) {
      return form.reset();
    },

    formResetValidation(form) {
      form.resetValidation();
    },
  },

};
