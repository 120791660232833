import moment from 'moment';

export default {
  required(value) {
    let locValue = '';
    if (value && typeof value === 'string') {
      locValue = value.trim();
    }
    return !!locValue;
  },
  time(value) {
    let locValue = '';
    if (value && typeof value === 'string') {
      locValue = value.trim();
    }
    return moment(locValue, 'HH:mm').isValid();
  },
  maxTime(value, maxTime) {
    const hours = +value.substring(0, 2);
    const minutes = +value.substr(value.length - 2);
    const maxHours = +maxTime.substring(0, 2);
    const maxMinutes = +maxTime.substr(maxTime.length - 2);
    return hours === maxHours
      ? hours <= maxHours && minutes <= maxMinutes
      : hours <= maxHours;
  },
  minTime(value, minTime) {
    const hours = +value.substring(0, 2);
    const minutes = +value.substr(value.length - 2);
    const minHours = +minTime.substring(0, 2);
    const minMinutes = +minTime.substr(minTime.length - 2);
    return hours === minHours
      ? hours >= minHours && minutes >= minMinutes
      : hours >= minHours;
  },
  selectRequired(value) {
    return !!value;
  },
  email(value) {
    const re = /\S+@\S+\.\w{1,5}\S+/;
    return re.test(value);
  },
  isNumber(value) {
    const reg = new RegExp('^[0-9]+$');
    return reg.test(value);
  },

  isNumberDot(value) {
    return /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(value);
  },
  password(value) {
    return /^\S{6,10}$/.test(value);
  },

  minChar(value, min) {
    if (!value) {
      return value;
    }
    return value.length >= min;
  },
  phone(value) {
    if (!value) {
      return value;
    }
    const reg = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$');
    return reg.test(value);
  },
  zipCode(value) {
    if (!value) {
      return value;
    }
    return value.length <= 10;
  },
  maxChar(value, max) {
    if (!value) {
      return value;
    }
    return value.length <= max;
  },
};
