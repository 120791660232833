<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="d-flex" cols="12" sm="6">
          <div class="form-group mt-0 pt-0" style="width: 100%;">
            <v-text-field
              class="treeview__search"
              rounded
              flat
              solo
              color="#595959"
              v-model="search"
              @input="searchByText"
              background-color="#F7F7F7"
              prepend-inner-icon="fa-search"
              :label="$t('message.search') + '...'"
              :disabled="loading"
              ref="search"
            />
          </div>
        </v-col>

        <v-col cols="12" sm="2">
          <div class="form-group mt-0 pt-0">
            <v-select
              v-if="getRoles"
              class="select_theme_default"
              :items="getRoles"
              v-model="roleSelected"
              @change="changeSelectRoles"
              item-value="id"
              :placeholder="$t('message.select')"
              :disabled="loading"
              outlined
            >
              <template slot="item" aria-selected="false" slot-scope="data">
                <div>
                  {{ data.item.title }}
                </div>
              </template>
              <template slot="selection" slot-scope="data">
                {{ data.item.title }}
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="d-flex justify-content-end">
            <v-btn
              class="mx-2"
              @click.stop="$emit('create')"
              fab small light
              color="green darken-2"
              :disabled="loading"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              @click.stop="removeMultiplyItem"
              fab small light
              color="red darken-2"
              :disabled="loading"
            >
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <Preloader overlay v-if="loading" />
      <v-data-table
        v-if="persons"
        :headers="headers"
        :items="persons"
        :server-items-length="totalDesserts"
        :items-per-page="options.itemsPerPage"
        :no-data-text="emptyTableText"
        class="elevation-1 table_theme_default"
        hide-default-footer
        fluid fill-width
      >
        <template v-slot:item.checkbox="{ item }">
          <v-checkbox v-model="selected" :value="item.id"></v-checkbox>
        </template>
        <template v-slot:item.id="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:item.group_students="{ item }">
          {{ _convertArrayToString(item.group_students, 'full_name') }}
        </template>
        <template v-slot:item.roles="{ item }">
          {{ _convertArrayToString(item.roles, 'title') }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="mx-2" small icon @click.prevent="$emit('edit', item)">
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          <v-btn class="mx-2" small icon @click.prevent="$emit('removeItem', item.id)">
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          class="pagination_theme_default"
          v-model="page"
          :length="totalPages"
          @input="updatePage" />
      </div>
    </v-container>
  </div>
</template>

<script>
import Preloader from '@components/Shared/Preloader.vue';

const configDefaultSelected = {
  id: 0,
  name: 'all',
  title: window.app.$t('All'),
};

export default {
  name: 'TablePersons',
  components: {
    Preloader,
  },
  props: {
    persons: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Array,
    },
    totalPages: {
      type: Number,
    },
    totalDesserts: {
      type: Number,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
    },
    headers: {
      type: Array,
    },
    emptyTableText: {
      type: String,
      default: 'No data available',
    },
  },
  computed: {
    getRoles() {
      if (this.roles) {
        return [
          configDefaultSelected,
          ...this.roles,
        ];
      }

      return null;
    },
  },
  data() {
    return {
      search: null,
      selected: [],
      roleSelected: configDefaultSelected,
      options: {
        itemsPerPage: 15,
      },
      page: 1,
    };
  },
  watch: {
    currentPage: {
      handler(newValue) {
        this.page = newValue;
      },
    },
  },
  methods: {
    updatePage(data) {
      this.$emit('updatePage', data);
    },
    searchByText(data) {
      this.$emit('updateSearchText', data);
    },
    removeMultiplyItem() {
      this.$emit('removeMultiplyItem', this.selected);
    },
    changeSelectRoles(roleId) {
      this.$emit('changeSelectRoles', roleId);
    },
  },
};
</script>

<!-- TODO Create Global style for input -->
<style lang="scss">
  .form-group {
    .v-text-field {
      .v-input__append-inner {
        margin-top: 9px;
        i {
          color: #969696;
        }
      }
      .v-text-field__details {
        margin-bottom: 5px;
        padding-left: 0;
      }
      .v-input__control .v-input__slot {
        padding: 0;
        min-height: 40px;
        box-shadow: none;
        input {
          padding: 0 12px;
          font-size: 14px;
          height: 100%;
        }
      }
      fieldset {
        border-color: $elementColor;
        border-width: 1px;
      }
      &.v-input--is-focused {
        fieldset {
          border-color: $accentColor;
        }
      }
      &.error--text {
        fieldset {
          border-color: $dangerColor;
        }
      }
    }
    .v-select {
      .v-select__slot {
        .v-select__selections {
          padding: 8px 12px !important;
          font-size: 14px;
          input {
            padding: 0;
          }
          .v-select__selection {
            margin: 3px 0;
          }
        }
      }
    }
  }

  .form-date-group {
    display: flex;
    flex-flow: row wrap;
  }

  .form-date-group__item {
    width: 25%;
    &.form-single-date {
      width: 40%;
    }
    &:not(:nth-child(3)) {
      margin-right: 5%;
    }
  }

  .form-group_date {
    .v-input__append-inner {
      position: absolute;
      right: 8px;
      pointer-events: none;
      .primary--text {
        color: $accentColor !important;
      }
    }
  }

  .form__teachers {
    margin-top: 20px;
  }

  .form-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .form-control__left {
      .m-button {
        margin-right: 25px;
      }
    }
  }

  .v-date-picker-table {
    .v-btn--outlined {
      background: $accentColor;
      color: #fff !important;
    }
    .v-btn--active {
      color: $accentColor !important;
      border: thin solid currentColor;
      background-color: #fff !important;
      &:before {
        background: #fff;
      }
    }
  }

  .recurring-event {
    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .recurring-event__add {
    color: $accentColor;
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    &.disabled {
      color: $elementColor;
      pointer-events: none;
    }
    span {
      cursor: pointer;
    }
  }
</style>
