<template>
  <v-dialog
    v-model="show"
    max-width="360"
    persistent
  >
    <Preloader overlay v-if="subjectLoading" />
    <v-card>
      <v-toolbar flat >
        <v-toolbar-title> {{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" >
          <v-row>
            <v-col cols="12">
              <div class="form-group">
                <v-text-field
                  id="name"
                  v-model="getName"
                  outlined
                  :placeholder="$t('message.name')"
                ></v-text-field>
                <label class="label" for="name">
                  {{$t('message.name')}}
                  <i class="warning-icon">!</i>
                </label>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pt-0 pa-5 mt-5">
        <v-btn
          class="m-button"
          text
          @click.prevent="hideModal"
        >
          {{ $t('message.cancel') }}
        </v-btn>
        <v-btn
          class="ml-auto m-button m-button_success"
          text
          @click.prevent="submitModal"
        >
          {{ $t('message.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import Preloader from '@components/Shared/Preloader.vue';
import rulesMixin from '@/mixins/Form/rules';

const config = {
  initForm: {
    name: '',
  },
};

export default {
  name: 'subjectsModal',
  components: {
    Preloader,
  },
  mixins: [
    rulesMixin,
  ],
  props: {
    value: {},
    date: {
      type: null,
      default: () => {},
    },
    roomId: {
      type: null,
      default: () => '',
    },
    title: {
      type: String,
      default: 'Create Subjects',
    },
    name: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      subjectLoading: 'subject/isLoading',
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    getName: {
      get() {
        return this.form.name || this.name;
      },
      set(value) {
        this.form.name = value;
      },
    },
  },
  data: () => ({
    form: { ...config.initForm },
  }),
  methods: {
    resetForm() {
      this.form = { ...config.initForm };
    },
    hideModal() {
      this.show = false;
      this.resetForm();
      this.formResetValidation(this.$refs.form);
      this.$emit('hide');
    },
    submitModal() {
      if (!this.formValidate(this.$refs.form)) return;
      this.$emit('submit', this.form);
      this.hideModal();
    },
  },
};
</script>
