import { debounce } from 'lodash';

export default {
  data: () => ({}),
  computed: {},
  methods: {
    _updatePage(currentPage) {
      this._updateTable(this._prepareDataForUpdateTable(currentPage));
    },
    _findPersonsByText: debounce(async function (query) {
      this.table.search = query;
      await this._updateTable(this._prepareDataForUpdateTable());
      // Vuetify can't work without timeout
      if (this.tableContainer) {
        setTimeout(() => {
          this.tableContainer.$refs.search.focus();
        }, 300);
      }
    }, 700),
    _updateTable(data) {
      return this.getDataTable({
        page: data.page || 1,
        search: data.search || null,
        role_id: data.role_id || null,
      });
    },
    _changeSelectUsersRoles(roleId) {
      this.table.role_id = roleId;
      this._updateTable(this._prepareDataForUpdateTable());
    },
    _prepareDataForUpdateTable(currentPage = 1) {
      return {
        page: currentPage,
        search: this.table.search,
        role_id: this.table.role_id,
      };
    },
  },
};
